import { post } from 'aws-amplify/api';
import moment from 'moment';
import { Converter } from 'aws-sdk/clients/dynamodb';
import _, { get, isEmpty, omit, sortBy } from 'lodash';
import { parseDynaomoParams } from '@/utils/utils';
import { getSupportTicketBySid } from './mongo';

const unmarshall = Converter.unmarshall;
const marshall = Converter.marshall;
const queryApi = '/sce_dynamo/a/query';

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;

export const getSEDAppointments = async ({ email, dateRange }) => {
	if (dateRange.end_date < dateRange.start_date) {
		throw new Error('Start date cannot be greater than End date');
	}
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					IndexName: 'gsi_1',
					KeyConditionExpression: 'gsi_pk_1 = :gsi_pk_1 and gsi_sk_1 between :start_time and :end_time',
					ExpressionAttributeValues: {
						':gsi_pk_1': { S: `doctor_email:${email}` },
						':start_time': {
							S: `appointment:${dateRange.start_date}`,
						},
						':end_time': {
							S: `appointment:${dateRange.end_date}`,
						},
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return sortBy(
		body.Items.map((item) => unmarshall(item)),
		(o) => -1 * (o.start_time || 0)
	);
};

export const getAppointmentDetail = async (uid, aid) => {
	const appointmentDetailRaw = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `appointment:${aid}` },
					},
				},
			},
		},
	}).response;

	const body = await appointmentDetailRaw.body.json();

	return unmarshall(get(body, 'Item', {}));
};

export const getClinic = async (cid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `cid:${cid}` },
						sk: { S: 'data' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	const result = unmarshall(_.get(body, 'Item', {}));

	return result;
};

export const confirmPaytmPayment = async (acquirementId, confirmeAt, data) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression:
						'set payment_status = :payment_status, confirmed_timestamp = :confirmed_timestamp, provider = :provider, acquirementId = :acquirementId, gsi_sk_4 = :gsi_sk_4',
					ExpressionAttributeValues: marshall({
						':payment_status': 'confirmed',
						':confirmed_timestamp': confirmeAt,
						':provider': 'paytm_pos',
						':acquirementId': acquirementId,
						':gsi_sk_4': 'payment_status:confirmed',
					}),
					Key: {
						pk: { S: data.pk },
						sk: { S: data.sk },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const checkPaytmPayment = async (uid, pid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: '/payment/u/clinic-payment/confirm-paytm-payment',
		options: {
			body: {
				uid,
				pid,
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const getPaytmMerchantTxnId = async (pid, tid, mid, merchant_key, amount) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: '/common/u/get-paytm-checksum',
		options: {
			body: {
				mode: 'init',
				body: {
					mid,
					tid,
					merchant_key,
					amount: amount,
					pid: pid,
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const getSuggestionDetail = async (uid, sid) => {
	const appointmentDetailRaw = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `treatment_suggestion:${sid}` },
					},
				},
			},
		},
	}).response;

	const body = await appointmentDetailRaw.body.json();

	return unmarshall(get(body, 'Item', {}));
};

export const getAppointmentSid = async (uid, sid) => {
	const suggestionDetails = await getSuggestionDetail(uid, sid);
	console.log('sugg:', suggestionDetails.aid);
	const appointmentDetails = await getAppointmentDetail(uid, suggestionDetails?.aid);
	console.log('Get:', appointmentDetails);
	return appointmentDetails;
};

export const getSuggestionTicketDetail = async (uid, sid) => {
	const appointmentDetailRaw = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `treatment_suggestion:${sid}` },
					},
				},
			},
		},
	}).response;

	const body = await appointmentDetailRaw.body.json();
	const resp = unmarshall(get(body, 'Item', {}));
	const ticketDetails = await getSupportTicketBySid(sid);
	const combinedData = { ...ticketDetails, ...resp, ticket_type: ticketDetails?.type };
	return combinedData;
};

export const updateAppointment = async (
	appointment,
	updateData,
	pathPrefix = '',
	globalUpdateData = {},
	removeData = {
		pathPrefix: '',
		keys: [],
	}
) => {
	const unix = moment().unix();
	const [updateExpSuffix, attributeValues] = parseDynaomoParams(updateData, pathPrefix);
	const [globalUpdateExpSuffix, globalAttributeValues] = parseDynaomoParams(globalUpdateData, '');

	// evaluate removal data
	let removeExp = '';
	if (!isEmpty(removeData.keys)) {
		if (!isEmpty(removeData.pathPrefix)) {
			removeData.keys = removeData.keys.map((k) => `${removeData.pathPrefix}.${k}`);
		}
		removeExp = ' REMOVE ' + removeData.keys.join(', ');
	}

	const UpdateExpression = `SET updated_at= :updated_at` + updateExpSuffix + globalUpdateExpSuffix + removeExp;
	const expressionAttributeValues = {
		':updated_at': unix,
		...attributeValues,
		...globalAttributeValues,
	};
	const key = {
		pk: { S: `uid:${get(appointment, 'uid')}` },
		sk: { S: `appointment:${get(appointment, 'aid')}` },
	};

	const mainExpression = {
		Key: key,
		UpdateExpression,
		ExpressionAttributeValues: marshall(expressionAttributeValues, {
			removeUndefinedValues: true,
		}),
	};
	let params = mainExpression;
	let command_name = 'UpdateItemCommand';

	if (!isEmpty(pathPrefix) && isEmpty(get(appointment, pathPrefix))) {
		await post({
			apiName: `backend_${environment}`,
			path: queryApi,
			options: {
				body: {
					command_name: command_name,
					params: {
						Key: key,
						UpdateExpression: 'SET #prefix = :emptyValue',
						ConditionExpression: 'attribute_not_exists(#prefix)',
						ExpressionAttributeValues: marshall({
							':emptyValue': {},
						}),
						ExpressionAttributeNames: {
							'#prefix': pathPrefix,
						},
					},
					supressConditionUpdateFailed: true,
				},
			},
		}).response.catch((error) => {
			console.error(`document path (${pathPrefix}) init failed, most probably the path already exists!`);
		});
	}
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: command_name,
				params,
			},
		},
	}).response;

	return response.body.json();
};

export const getPatientProfile = async (uid) => {
	// console.log("getPatientProfile", uid);
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `account` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return unmarshall(get(body, 'Item', {}));
};

export const getAppointments = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					FilterExpression: 'item_type = :item_type',
					ProjectionExpression:
						'pk, sk, appointment_type, sed_questionnaire, note, start_time,end_time, customer_photo, city, purpose, cid, clinic_name, aid, appointment_status,uid, locus_agent_id, is_walkin, suggestion_type, ticket_type',
					ExpressionAttributeValues: {
						':pk': { S: `uid:${uid}` },
						':sk': { S: 'appointment:' },
						':item_type': { S: 'appointment' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getCities = async () => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: 'config' },
						sk: { S: 'city_list' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	const result = unmarshall(_.get(body, 'Item', {}));
	return _.get(result, 'city_list', []);
};

export const getWalkinClinics = async (city) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					IndexName: 'gsi_3',
					ProjectionExpression: 'cid, clinic_url,clinic_name, address, address_text',
					KeyConditionExpression: 'gsi_pk_3 = :gsi_pk_3 and gsi_sk_3 = :gsi_sk_3',
					FilterExpression: 'signature_clinic = :signature_clinic and active = :active',
					ExpressionAttributeValues: marshall({
						':gsi_pk_3': `clinic:city:${city}`,
						':gsi_sk_3': 'data',
						':signature_clinic': true,
						':active': true,
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getAptSuggestions = async ({ aid }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					IndexName: 'gsi_3',
					KeyConditionExpression: 'gsi_pk_3 = :gsi_pk_3 and begins_with(gsi_sk_3, :gsi_sk_3)',
					ExpressionAttributeValues: marshall({
						':gsi_pk_3': `appointment:${aid}`,
						':gsi_sk_3': `suggestion:`,
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getTreatmentPayments = async (tid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'gsi_pk_1 = :gsi_pk_1 and begins_with(gsi_sk_1, :gsi_sk_1)',
					IndexName: 'gsi_1',
					ScanIndexForward: false,
					ExpressionAttributeValues: marshall({
						':gsi_pk_1': `treatment:${tid}`,
						':gsi_sk_1': 'payment',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getPaymentImage = async (key, uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: '/download/u/generate-link',
		options: {
			body: {
				key,
				uid,
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const getPaymentsByRefId = async (ref_id) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'gsi_pk_3 = :gsi_pk_3 and begins_with(gsi_sk_3, :gsi_sk_3)',
					IndexName: 'gsi_3',
					ScanIndexForward: false,
					ExpressionAttributeValues: marshall({
						':gsi_pk_3': `ref_id:${ref_id}`,
						':gsi_sk_3': 'payment',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getTreatmentByTid = async (tid, uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `treatment:${tid}` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();
	const result = unmarshall(_.get(body, 'Item', {}));

	return result;
};

export const couponDetails = async (treatment_id, uid, amount, sugg_split) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: '/coupon_details/u/get',
		options: {
			body: {
				treatment_id,
				uid,
				amount,
				sugg_split,
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const createClinicPayment = async (
	uid,
	tid,
	paymentSplit,
	amount,
	original_amount,
	loyalty_discount,
	membership_discount,
	payment_mode_data
) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: '/payment/u/clinic-payment/create',
		options: {
			body: {
				uid,
				tid,
				split_meta: paymentSplit,
				amount,
				original_amount,
				loyalty_discount,
				membership_discount,
				payment_mode_data,
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const cancelPayment = async (uid, tid, pid, cancelled_by) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: '/payment/u/clinic-payment/cancel',
		options: {
			body: {
				uid,
				tid,
				pid,
				cancelled_by,
			},
		},
	}).response;

	const body = await response.body.json();
	return body;
};

export const getTreatmentSuggestions = async (tid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'gsi_pk_1 = :gsi_pk_1 and begins_with(gsi_sk_1, :gsi_sk_1)',
					IndexName: 'gsi_1',
					ScanIndexForward: false,
					ExpressionAttributeValues: marshall({
						':gsi_pk_1': `treatment:${tid}`,
						':gsi_sk_1': 'suggestion',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getSuggestionByUid = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ScanIndexForward: false,
					ExpressionAttributeValues: marshall({
						':pk': `uid:${uid}`,
						':sk': 'treatment_suggestion',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
}

export const getDoctors = async ({ cid, date }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `cid:${cid}` },
						sk: { S: `roster:${date}` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	const result = unmarshall(_.get(body, 'Item', {}));
	return _.get(result, 'roster', []);
};

export const createWalkinAppointment = async (data) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params: {
					Item: marshall(data),
				},
			},
		},
	}).response;

	return response;
};

export const getDeclarations = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ProjectionExpression: 'pk, sk, signature_key, user_declaration_confirmed_at',
					ExpressionAttributeValues: {
						':pk': { S: `uid:${uid}` },
						':sk': { S: 'declaration_form:' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getUserTreatmentSuggestion = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ExpressionAttributeValues: {
						':pk': { S: `uid:${uid}` },
						':sk': { S: 'treatment_suggestion:' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getTreatmentList = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ProjectionExpression:
						'pk, sk, tid, treatment_type, treatment_cost, total_payment_received, total_payment_refunded, treatment_list, user_consent_confirmed_at, signature_key, clinic_name, city, cid, doctor_name, notes, notes_list, created_at,is_treatment_suggestion,is_pft, pft_at, pft_by, subscription_status',
					ExpressionAttributeValues: {
						':pk': { S: `uid:${uid}` },
						':sk': { S: 'treatment:' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const addExamination = async (data) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params: {
					Item: marshall(data),
				},
			},
		},
	}).response;

	return response;
};

export const getTreatmentSuggestionMaster = async () => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: 'treatment_suggestion_master' },
						sk: { S: 'data' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();
	const parsed = unmarshall(get(body, 'Item', {}));
	return (
		parsed?.data?.map((item, index) => ({
			...item,
			id: index + 1, // You can use any logic to generate the ID
		})) ?? []
	);
};
export const getEmployeeByEmail = async (email) => {
	const employee = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					IndexName: 'gsi_1',
					KeyConditionExpression: 'gsi_pk_1 = :gsi_pk_1',
					ExpressionAttributeValues: {
						':gsi_pk_1': { S: `email:${email}` },
					},
				},
			},
		},
	}).response;

	const body = await employee.body.json();

	return unmarshall(get(body, 'Items', [])[0]);
};

export const getExamination = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ExpressionAttributeValues: {
						':pk': { S: `uid:${uid}` },
						':sk': { S: 'examination' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const cancelSuggestion = async (data) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',

				params: {
					UpdateExpression:
						'set #status = :status, updated_by = :updated_by, updated_at = :updated_at, notes = :notes, cancel_reason = :cancel_reason',
					ExpressionAttributeNames: {
						'#status': 'status',
					},
					ExpressionAttributeValues: marshall(omit(data, ['pk', 'sk'])),
					Key: marshall({
						pk: data.pk,
						sk: data.sk,
					}),
				},
			},
		},
	}).response;
};

export const updateParentSuggestions = async (data) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',

				params: {
					UpdateExpression: 'set is_parent = :is_parent',
					ExpressionAttributeValues: marshall({
						':is_parent': true,
					}),
					Key: marshall(data),
				},
			},
		},
	}).response;
};

export const detachSuggestion = async (data) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression:
						'SET detach_tid = :detach_tid, detached_by = :detached_by, detached_at = :detached_at REMOVE tid , gsi_pk_1, gsi_sk_1',
					ExpressionAttributeValues: marshall(omit(data, ['pk', 'sk'])),
					Key: marshall({
						pk: data.pk,
						sk: data.sk,
					}),
				},
			},
		},
	}).response;
};

export const updateTreatmentCost = async (data) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: 'SET treatment_cost = :treatment_cost',
					ExpressionAttributeValues: marshall(omit(data, ['pk', 'sk'])),
					Key: marshall({
						pk: data.pk,
						sk: data.sk,
					}),
				},
			},
		},
	}).response;
};

export const updateChildSuggestions = async ({ updateExpression, body, keys }) => {
	console.log('updateExpression', updateExpression, body, keys);

	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',

				params: {
					UpdateExpression: updateExpression,
					ExpressionAttributeValues: marshall({
						...body,
					}),
					Key: marshall({
						...keys,
					}),
				},
			},
		},
	}).response;
};

export const sendInhouseOtp = (appointment) => {
	return post({
		apiName: `backend_${environment}`,
		path: '/appointment/a/send_inhouse_otp',
		options: {
			body: {
				uid: appointment.uid,
				aid: appointment.aid,
			},
		},
	}).response;
};

export const updateTreatmentPricing = async ({ keys, data, updateExpression }) => {
	// console.log("updateTreatmentPricing", updateExpression, data);
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',

				params: {
					UpdateExpression: updateExpression,
					ExpressionAttributeValues: marshall(data, {
						removeUndefinedValues: true,
					}),
					Key: marshall(keys, {
						removeUndefinedValues: true,
					}),
				},
			},
		},
	}).response;
};

export const createClinicTreatment = async (data) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params: {
					Item: marshall(data, {
						removeUndefinedValues: true,
					}),
				},
			},
		},
	}).response;

	return response;
};

export const getDocumentList = async (uid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ExpressionAttributeValues: marshall({
						':pk': `uid:${uid}`,
						':sk': 'document_list',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const addPatientDocument = async (uid, documentData, userEmail) => {
	const params = {
		Item: marshall(
			{
				pk: `uid:${uid}`,
				sk: `document_list:${moment().unix()}`,
				created_at: moment().unix(),
				created_by: userEmail,
				s3_key: documentData.attachment.key,
				document_type: documentData.document_type,
				tid: documentData.treatment,
			},
			{ removeUndefinedValues: true }
		),
	};

	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params,
			},
		},
	}).response;

	return response;
};

export const updateSuggestion = async ({ keys, body, updateExpression }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: updateExpression,
					ExpressionAttributeNames: {
						'#sug_status': 'status',
					},
					ExpressionAttributeValues: marshall(body, {
						removeUndefinedValues: true,
					}),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const updateSuggestionCbctAttachments = async ({ keys, body }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					ExpressionAttributeNames: {
						'#cbct_attachments': 'cbct_attachments',
					},
					UpdateExpression:
						'SET #cbct_attachments = list_append(if_not_exists(#cbct_attachments, :emptyList), :newAttachment)',
					ExpressionAttributeValues: marshall(body, {
						removeUndefinedValues: true,
					}),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const updateSuggestionCbctFile = async ({ keys, body }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					ExpressionAttributeNames: {
						'#cbct_file': 'cbct_file',
					},
					UpdateExpression: 'SET #cbct_file = :newAttachment',
					ExpressionAttributeValues: marshall(body, {
						removeUndefinedValues: true,
					}),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const updateTreatmentSuggestions = async ({ keys, body }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: 'set treatment_cost = :treatment_cost',
					ExpressionAttributeValues: marshall(body, {
						removeUndefinedValues: true,
					}),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const getClinicDetail = async (cid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: marshall({
						pk: `cid:${cid}`,
						sk: 'data',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return unmarshall(body.Item || {});
};

export const getClinicAppointments = async (cid, filters = {}) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					IndexName: 'gsi_4',
					KeyConditionExpression: 'gsi_pk_4 = :gsi_pk_4 and (gsi_sk_4 between :gsi_sk_start and :gsi_sk_end)',
					FilterExpression: [
						filters.speciality ? 'speciality = :speciality' : null,
						filters.appointment_status ? 'appointment_status = :appointment_status' : null,
						'start_time between :start_time_start and :start_time_end',
						filters.purpose ? 'purpose = :purpose' : null,
						filters.customer_mobile ? 'contains(customer_mobile, :customer_mobile)' : null,
						filters.ticket_type ? 'ticket_type = :ticket_type' : null,
						filters.suggestion_type ? 'suggestion_type = :suggestion_type' : null,
					]
						.filter((item) => item !== null)
						.join(' and '),
					ExpressionAttributeValues: marshall({
						':gsi_pk_4': `cid:${cid}`,
						':gsi_sk_start': `appointment:${filters.start_time}`,
						':gsi_sk_end': `appointment:${filters.end_time}`,
						':start_time_start': filters.start_time,
						':start_time_end': filters.end_time,
						':speciality': filters.speciality,
						':appointment_status': filters.appointment_status,
						':purpose': filters.purpose,
						':ticket_type': filters.ticket_type,
						':suggestion_type': filters.suggestion_type,
						':customer_mobile': filters.customer_mobile ? filters.customer_mobile : undefined,
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return body?.Items.map((item) => unmarshall(item));
};

export const getMonitorData = async ({ uid, mid }) => {
	const userRaw = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `monitor_data:${mid}` },
					},
				},
			},
		},
	}).response;

	const body = await userRaw.body.json();
	return unmarshall(get(body, 'Item', {}));
};

export const updateMonitorData = async (data, updateExpression, keys, attributeName) => {
	return await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: updateExpression,
					ExpressionAttributeValues: marshall(data),
					Key: marshall(keys),
					ExpressionAttributeNames: attributeName,
				},
			},
		},
	}).response;
};

export const getTeethReplacementInialPoints = (params) => {
	return post({
		apiName: `backend_${environment}`,
		path: '/teethswapha/u/get_dlib',
		options: {
			body: {
				uid: params.uid,
				mid: params.mid,
				pose: params.pose,
			},
		},
	}).response;
};

export const getUser = async ({ uid }) => {
	const userRaw = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: 'account' },
					},
				},
			},
		},
	}).response;

	const body = await userRaw.body.json();
	return unmarshall(get(body, 'Item', {}));
};

export const getMonitorDataObjects = async (doctor_email) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					IndexName: 'gsi_5',
					KeyConditionExpression: 'gsi_pk_5 = :gsi_pk_5 and begins_with(gsi_sk_5, :prefix1)',
					ExpressionAttributeValues: {
						':gsi_pk_5': { S: `doctor:${doctor_email}` },
						':prefix1': { S: 'source:' },
					},
					ScanIndexForward: false,
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return body.Items.map((item) => unmarshall(item));
};

export const saveShortUrl = async ({ link, url }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params: {
					TableName: 'prod-shortUrl',
					Item: marshall({
						pk: `links:${link}`,
						sk: 'links',
						url: url,
						created_at: moment().unix(),
					}),
					ConditionExpression: 'attribute_not_exists(pk)',
				},
			},
		},
	}).response;

	return response;
};

export const updateTreatmentClinic = async ({ keys, body }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: 'set city = :city, cid = :cid, clinic_name = :clinic_name',
					ExpressionAttributeValues: marshall(body, {
						removeUndefinedValues: true,
					}),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const updateProfile = async ({ keys, updateExpression, data, attributeName }) => {
	return await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: updateExpression,
					ExpressionAttributeValues: marshall(data),
					Key: marshall(keys),
					ExpressionAttributeNames: attributeName,
				},
			},
		},
	}).response;
};

export const createProfile = async (data) => {
	console.log('createProfile', data);
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params: {
					Item: marshall(data),
				},
			},
		},
	}).response;

	return response;
};

export const getActiveEmployees = async () => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: 'active_emp_list' },
						sk: { S: 'data' },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return Object.values(unmarshall(get(body, 'Item', { emp_list: {} })).emp_list || {});
};

export const getSuggestionsFromTid = async ({ uid, tid }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					FilterExpression: 'tid = :tid',
					ExpressionAttributeValues: {
						':pk': { S: `uid:${uid}` },
						':sk': { S: 'treatment_suggestion:' },
						':tid': { S: `${tid}` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return body.Items.map((item) => unmarshall(item));
};

export const getTreatmentFromTid = async ({ uid, tid }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `treatment:${tid}` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return unmarshall(body.Item);
};

export const getPrescription = async ({ uid }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'QueryCommand',
				params: {
					KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
					ExpressionAttributeValues: marshall({
						':pk': `uid:${uid}`,
						':sk': 'prescription',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return body.Items.map((item) => unmarshall(item));
};

export const createPrescription = async (data) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'PutItemCommand',
				params: {
					Item: marshall(data),
				},
			},
		},
	}).response;

	return response;
};

export const updatePrescription = async ({ keys, body }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: 'set medicines = :medicines, updated_by = :updated_by, updated_at = :updated_at',
					ExpressionAttributeValues: marshall(body),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const getClinicToSendNotification = async (cid) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				ProjectionExpression: 'mobile, clinic_name, address_text, is_btl_clinic',
				params: {
					Key: marshall({
						pk: `cid:${cid}`,
						sk: 'data',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	const result = unmarshall(_.get(body, 'Item', {}));

	return result;
};

export const getMedicines = async () => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: marshall({
						pk: 'medicine',
						sk: 'data',
					}),
				},
			},
		},
	}).response;

	const body = await response.body.json();

	const result = unmarshall(_.get(body, 'Item', {}));
	return _.get(result, 'medicine_list', []);
};

export const addMedicine = async ({ keys, body }) => {
	await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					UpdateExpression: 'set medicines = :medicines, updated_by = :updated_by, updated_at = :updated_at',
					ExpressionAttributeValues: marshall(body),
					Key: marshall(keys),
				},
			},
		},
	}).response;
};

export const getSuggestion = async ({ uid, sid }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `treatment_suggestion:${sid}` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();

	return unmarshall(body.Item);
};

export const getTreatment = async ({ uid, tid }) => {
	const response = await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'GetItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: `treatment:${tid}` },
					},
				},
			},
		},
	}).response;

	const body = await response.body.json();
	return unmarshall(body.Item);
};

export const updateUserLevelInfo = async (uid, data) => {
	return await post({
		apiName: `backend_${environment}`,
		path: queryApi,
		options: {
			body: {
				command_name: 'UpdateItemCommand',
				params: {
					Key: {
						pk: { S: `uid:${uid}` },
						sk: { S: 'account' },
					},
					UpdateExpression: 'set questionnaire = :questionnaire',
					ExpressionAttributeValues: marshall({
						':questionnaire': data,
					}),
				},
			},
		},
	}).response;
};
