import { isEmpty } from "lodash";
import moment from "moment";
const TYPE_COLORS = [
  "blue",
  "purple",
  "cyan",
  "green",
  "magenta",
  "pink",
  "red",
  "orange",
  "yellow",
  "volcano",
  "geekblue",
  "lime",
  "gold",
];

function maskMobileNumber(number) {
  if (number === null || number === undefined) {
    return "";
  }
  if (typeof number !== "string") {
    number = number.toString();
  }

  if (number.length <= 6) {
    return "*".repeat(number.length);
  }

  const maskedPart = "*".repeat(6);
  const visiblePart = number.slice(6);

  return maskedPart + visiblePart;
}

function snakeToTitle(snakeString) {
  if (snakeString === null || snakeString === undefined) {
    return "";
  }
  return snakeString
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function emailValidator(value) {
  return new Promise((resolve, reject) => {
    const email_regex = /^[\w\d]+?[\w.\d]+@[\w\d]+\.[\w]{2,3}$/;
    if (email_regex.test(value)) {
      resolve();
    } else reject("Invalid Email");
  });
}

function mobileValidator(value) {
  return new Promise((resolve, reject) => {
    const email_regex = /^[1-9]\d{0,11}$/;
    if (isEmpty(value) || email_regex.test(value)) {
      resolve();
    } else reject("Invalid Mobile");
  });
}

const parseDynaomoParams = (data, deepPrefix = "") => {
  let expr = "";
  let values = {};
  if (deepPrefix.length > 0) {
    deepPrefix += ".";
  }
  Object.entries(data || {}).forEach(([key, val]) => {
    if (val) {
      expr += `, ${deepPrefix}${key} = :${key}`;
      values[`:${key}`] = val;
    }
  });
  return [expr, values];
};

function getRandomInt(max) {
  const min = Math.ceil(0);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const constructQueryString = (params) => {
  console.log(params);
  return Object.keys(params)
    .filter((key) => params[key])
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

function calculatePerImplantCost(implantNos) {
  if (implantNos == 1) {
    return 20000;
  } else {
    return 20000;
  }
}

function calculatePerCrownCost(crownType) {
  if (crownType == "PFM") {
    return 5000;
  } else {
    return 10000;
  }
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const implantPriceCalculator = ({
  implantPlanType,
  numberOfImplants = 0,
  numberOfCrowns = 0,
  additionalCost = 0,
  crownType,
}) => {
  //update discount calcultor if prices are changed over here

  let totalCost = 0;
  if (implantPlanType == "individual") {
    totalCost =
      parseFloat(numberOfImplants) * calculatePerImplantCost(numberOfImplants) +
      parseFloat(numberOfCrowns) * calculatePerCrownCost(crownType) +
      parseFloat(additionalCost);
  }
  if (implantPlanType == "single_arch") {
    totalCost = 125000 + parseFloat(additionalCost);
  }
  if (implantPlanType == "full_mouth") {
    totalCost = 225000 + parseFloat(additionalCost);
  }
  if (implantPlanType == "single_arch_individual") {
    totalCost =
      125000 +
      parseFloat(numberOfImplants) * calculatePerImplantCost(numberOfImplants) +
      parseFloat(numberOfCrowns) * calculatePerCrownCost(crownType) +
      parseFloat(additionalCost);
  }
  return parseFloat(totalCost);
};
// const implantPriceCalculator = ({
//   implantPlanType,
//   numberOfImplants = 0,
//   numberOfCrowns = 0,
//   additionalCost = 0,
//   crownType,
// }) => {
//   //update discount calcultor if prices are changed over here

//   let totalCost = 0;
//   if (implantPlanType == "individual") {
//     totalCost =
//       parseFloat(numberOfImplants) * calculatePerImplantCost(numberOfImplants) +
//       parseFloat(numberOfCrowns) * calculatePerCrownCost(crownType) +
//       parseFloat(additionalCost);
//   }
//   if (implantPlanType == "single_arch") {
//     totalCost = 125000 + parseFloat(additionalCost);
//   }
//   if (implantPlanType == "full_mouth") {
//     totalCost = 225000 + parseFloat(additionalCost);
//   }
//   if (implantPlanType == "single_arch_individual") {
//     totalCost =
//       125000 +
//       parseFloat(numberOfImplants) * calculatePerImplantCost(numberOfImplants) +
//       parseFloat(numberOfCrowns) * calculatePerCrownCost(crownType) +
//       parseFloat(additionalCost);
//   }
//   return parseFloat(totalCost);
// };

const getShorUrl = ({ mid, purpose, uid }) => {
  const redirectUrl = `${process.env.NEXT_PUBLIC_DEZY_URL}/in/ppr/shi/preview?stage=shi&mid=${mid}&uid=${uid}&purpose=${purpose}&is_preview=true`;
  function makeid(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return {
    link: makeid(5),
    url: redirectUrl,
  };
};

function removeEmptyKeys(obj) {
  return Object.entries(obj)
    .filter((e) => typeof e[1] === "number" || !isEmpty(e[1]))
    .reduce((p, c) => {
      p[c[0]] = c[1];
      return p;
    }, {});
}

function formatIfValidUnix(value) {
  const startUnix = moment("1970-01-01").unix();
  const endUnix = moment("2100-01-01").unix();

  if (
    typeof value === "number" &&
    value.toString().length === 10 &&
    value >= startUnix &&
    value <= endUnix
  ) {
    const momentInstance = moment.unix(value);

    if (momentInstance.isValid()) {
      return momentInstance.format("YYYY-MM-DD HH:mm:ss");
    }
  }

  return value;
}

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export {
  TYPE_COLORS,
  formatIfValidUnix,
  maskMobileNumber,
  snakeToTitle,
  emailValidator,
  parseDynaomoParams,
  getRandomInt,
  makeid,
  constructQueryString,
  implantPriceCalculator,
  mobileValidator,
  getShorUrl,
  removeEmptyKeys,
  isImage
};

export function encode(value) {
  return btoa(value);
}

export function decode(value) {
  console.log(value);
  if (value === null || value === undefined) {
    return "";
  }
  return atob(value);
}

export const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export function cleanObject(obj) {
  return _.pickBy(obj, (value) => {
    // Keep the field if its value is not undefined, null, NaN, or an empty string
    return (
      !_.isUndefined(value) &&
      !_.isNull(value) &&
      !_.isNaN(value) &&
      !(_.isString(value) && _.isEmpty(value))
    );
  });
}

export function updateExpressionWithMissingKeys(updateExpression, body) {
  // Extract all keys from the existing updateExpression
  const keysInString = updateExpression.match(/:\w+/g) || [];

  // Filter the keys in temp1 that are not in the updateExpression
  const missingKeys = Object.keys(body).filter(key => !keysInString.includes(key));

  // If there are missing keys, append them to the updateExpression
  if (missingKeys.length > 0) {
    const missingFields = missingKeys.map(key => {
      const field = key.replace(":", ""); // Remove ':' from the key for the field name
      return `${field} = ${key}`;
    });

    // Append the missing fields to the original updateExpression
    updateExpression += `, ${missingFields.join(", ")}`;
  }

  return updateExpression;
}

export function removeMissingKeysFromExpression(updateExpression, body) {
  // Extract all keys from the updateExpression
  let keysInExpression = updateExpression.match(/:\w+/g) || [];

  // Filter out the keys from updateExpression that are not in body
  let filteredKeys = keysInExpression.filter(key => Object.keys(body).includes(key));

  // Create a new updateExpression with only the filtered keys
  let filteredExpression = updateExpression.split(",").filter(segment => {
    // Check if any key from body exists in the current segment
    return filteredKeys.some(key => segment.includes(key));
  }).join(",");
  return `${filteredExpression.trim()}`;
}