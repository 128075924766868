import { post } from 'aws-amplify/api';
import moment from 'moment';

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
const queryApi = '/sce_general/a/mongo';

export const getSupportTicket = async (ticket_id) => {
	let ticket = null;
	if (ticket_id) {
		ticket = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/getitem`,
			options: {
				body: {
					collection: 'tickets',
					filter: { _id: { $oid: ticket_id } },
				},
			},
		}).response;
	}

	const response = await ticket.body.json();

	return response;
};

export const getSupportTicketBySid = async (sid) => {
	let ticket = null;
	if (sid) {
		ticket = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/getitem`,
			options: {
				body: {
					collection: 'tickets',
					filter: { sid: sid, type: 'implant_initial_cbct' },
				},
			},
		}).response;
	}

	const response = await ticket.body.json();

	return response;
};

export const getSupportTicketByUid = async (uid) => {
	let ticket = null;
	if (uid) {
		ticket = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/queryitems`,
			options: {
				body: {
					collection: 'tickets',
					query: { filter:{ uid } },
				},
			},
		}).response;
	}
	const response = await ticket.body.json();
	return response;
};

export const deleteSupportTicketFiles = async (ticket_id) => {
	let response = null;
	if (ticket_id) {
		response = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/updateitem`,
			options: {
				body: {
					collection: 'tickets',
					filter: { _id: { $oid: ticket_id } },
					update: {
						$unset: {
							files: '',
						},
					},
				},
			},
		}).response;
	}

	return await response.body.json();
};

export const updateSupportTicketFiles = async (ticket_id, pathKey) => {
	let response = null;
	if (ticket_id) {
		response = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/updateitem`,
			options: {
				body: {
					collection: 'tickets',
					filter: { _id: { $oid: ticket_id } },
					update: {
						$set: {
							files: pathKey,
						},
					},
				},
			},
		}).response;
	}
	return await response.body.json();
};

export const updateTicketAttachmentList = async (ticket_id, pathKey, updated_by) => {
	let response = null;
	if (ticket_id) {
		response = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/updateitem`,
			options: {
				body: {
					collection: 'tickets',
					filter: { _id: { $oid: ticket_id } },
					update: {
						$push: {
							attachments: {
								key: pathKey,
								at: moment().unix(),
								by: updated_by,
							},
						},
					},
				},
			},
		}).response;
	}
	return await response.body.json();
};

export const getAptLead = async (appointment) => {
	let lead = null;
	if (appointment?.lead_id || appointment?.aid) {
		lead = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/getitem`,
			options: {
				body: {
					collection: 'leads',
					filter: appointment.lead_id ? { _id: { $oid: appointment.lead_id } } : { aid: { $eq: appointment.aid } },
				},
			},
		}).response;
	}
	return await lead.body.json();
};

export const getTicketsBySid = async (sid) => {
	let ticket = null;
	if (sid) {
		ticket = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/queryitems`,
			options: {
				body: {
					collection: 'tickets',
					query: { filter: { sid: sid, aid : { $exists: false} }, sort: {raised_at: -1}},
					// sort: { raised_at: -1 }
				},
			},
		}).response;
	}

	const response = await ticket.body.json();

	return response;
}

export const createTicketForFollowUp = async (ticketObj) => {
	let ticket = null;
	if (ticketObj) {
		ticket = await post({
			apiName: `backend_${environment}`,
			path: `${queryApi}/createitem`,
			options: {
				body: {
					collection: 'tickets',
					item: {'document': ticketObj},
					// sort: { raised_at: -1 }
				},
			},
		}).response;
	}

	const response = await ticket.body.json();

	return response;
}
